
import {
  Component,
  namespace,
  Prop,
  Vue,
  Watch,
} from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import { ButtonText } from "~/operations/messages";
import sendGtmEvent from "~/operations/send-gtm-event";

const CalculatorModule = namespace("calculator");

interface InputValues {
  zipCode: string;
  amount: number | string;
}

interface SupportMessages {
  zipCode: string;
  amount: string;
}

@Component({
  components: {
    RouterLink,
  },
})
export default class ReducedPriceComparison extends Vue {
  // Props with default values
  @Prop({ default: "small" }) variant!: "small" | "bordered";
  @Prop({ default: "all" }) paymentType!: string;
  @Prop({ default: "oil" }) inputType!: "oil" | "pellets";
  @Prop({ default: "Heizöl-Preisvergleich:" }) titleText!: string;
  @Prop({ default: "Liter" }) amountLabel!: string;
  @Prop({ default: "Ihr Bedarf" }) amountPlaceholder!: string;
  @Prop({ default: ButtonText.compareOilPrices }) buttonText!: string;
  @Prop({ default: 3000 }) defaultAmount!: number;
  @Prop({ default: 500 }) minAmount!: number;
  @Prop({ default: 32000 }) maxAmount!: number;

  oilValues: InputValues = {
    zipCode: "",
    amount: this.defaultAmount,
  };

  pelletsValues: InputValues = {
    zipCode: "",
    amount: this.defaultAmount,
  };

  supportMessages: SupportMessages = {
    zipCode: "",
    amount: "",
  };

  // Vuex actions and state
  @CalculatorModule.Action("fetchAvailableOptions")
  fetchAvailableOptions!: any;

  @CalculatorModule.State("availabilities")
  availabilities!: any;

  mounted() {
    this.tryLocalStoragePreset();
    this.onResize();
    window.addEventListener("resize", this.onResize);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize() {
    const zipInput = document.getElementById(
      `header-${this.inputType}-zip-input`,
    ) as HTMLInputElement;
    const inputLabel = zipInput?.parentNode?.firstChild as HTMLElement;

    if (!zipInput || !inputLabel) return;

    if (window.innerWidth <= 400) {
      inputLabel.innerHTML = "PLZ";
      zipInput.placeholder = "21339";
    } else {
      inputLabel.innerHTML = "Postleitzahl";
      zipInput.placeholder = "z.B. 21339";
    }
  }

  updateZipCode(value: string): void {
    if (this.inputType === "pellets") {
      this.pelletsValues.zipCode = value;
    } else {
      this.oilValues.zipCode = value;
    }
  }

  updateAmount(value: number): void {
    if (this.inputType === "pellets") {
      this.pelletsValues.amount = value;
    } else {
      this.oilValues.amount = value;
    }
  }

  tryLocalStoragePreset() {
    const storageKeys = this.getStorageKeys();

    // Get zipcode
    const userPresetZipCode = localStorage.getItem(storageKeys.zipCode);
    if (userPresetZipCode?.length === 5) {
      this.updateZipCode(userPresetZipCode);
    }

    // Get amount
    const userPresetAmount = Number(localStorage.getItem(storageKeys.amount));
    if (userPresetAmount && userPresetAmount >= this.minAmount) {
      this.updateAmount(userPresetAmount);
    }
  }

  // Storage keys based on input type
  getStorageKeys() {
    return this.inputType === "pellets"
      ? { zipCode: "pelletsZipCode", amount: "pelletsAmount" }
      : // Keep original keys for oil
        { zipCode: "userZipCode", amount: "userOilAmount" };
  }

  setLocalStoragePreset(zipcode: string, amount: number) {
    const storageKeys = this.getStorageKeys();
    if (zipcode.length === 5) {
      localStorage.setItem(storageKeys.zipCode, zipcode);
    }
    localStorage.setItem(storageKeys.amount, amount.toString());
  }

  setButtonVariant() {
    if (this.variant === "small") return "small";
    if (this.variant === "bordered") return "black";
    return this.inputType === "pellets" ? "primary" : "default";
  }

  validateInputs() {
    let isValid = true;
    const zipcodeErrorMsg = "Ungültige Postleitzahl";

    // Validate ZIP code
    if (!this.inputValues.zipCode || this.inputValues.zipCode.length !== 5) {
      this.supportMessages.zipCode = zipcodeErrorMsg;
      isValid = false;
    } else {
      this.supportMessages.zipCode = "";
    }

    // Validate amount
    const amount = Number(this.inputValues.amount);
    if (!this.inputValues.amount || amount < this.minAmount) {
      this.supportMessages.amount = `Minimum ${this.minAmount.toLocaleString()} ${
        this.amountLabel
      }`;
      isValid = false;
    } else if (amount > this.maxAmount) {
      this.supportMessages.amount = `Maximum ${this.maxAmount.toLocaleString()} ${
        this.amountLabel
      }`;
      isValid = false;
    } else {
      this.supportMessages.amount = "";
    }

    return isValid;
  }

  async redirectToComparsionPage() {
    if (!this.validateInputs()) {
      return;
    }

    this.setLocalStoragePreset(
      this.inputValues.zipCode,
      Number(this.inputValues.amount),
    );

    try {
      await this.fetchAvailableOptions({
        isPellets: this.inputType === "pellets",
        zipcode: this.inputValues.zipCode,
        amount: this.inputValues.amount,
      });

      this.$router.push({
        path:
          this.inputType === "pellets"
            ? "/pelletspreise/bestellung"
            : "/bestellung",
        query: this.getQueryParams(),
      });

      this.sendAnalyticsEvent();
    } catch (error) {
      console.error("Error fetching available options:", error);
    }
  }

  getQueryParams(): Record<string, string> {
    const baseParams = {
      zipcode: this.inputValues.zipCode,
      amount: this.inputValues.amount.toString(),
      unloading_points: "1",
      deliveryTimes: "normal",
    };

    if (this.inputType === "pellets") {
      return {
        ...baseParams,
        quality_steps: "isoNorm, enPlus, dinPlus",
        payment_type: "all",
        prod: "loose",
        hose: "thirtyMetre",
        short_vehicle: "withTrailer",
      };
    }

    return {
      ...baseParams,
      payment_type: this.availablePayment,
      prod: "normal",
      hose: "fortyMetre",
      short_vehicle: "withTrailer",
    };
  }

  sendAnalyticsEvent() {
    sendGtmEvent(this, {
      category: "Navigation",
      label: `Nav CTA ${this.inputType.toUpperCase()} PV`,
      action: "submit",
    });
  }

  @Watch("inputType")
  onInputTypeChange(): void {
    this.tryLocalStoragePreset();
  }

  get availablePayment() {
    return this.availabilities.paymentTypes?.includes(this.paymentType)
      ? this.paymentType
      : this.availabilities.paymentTypes?.[0] || "all";
  }

  get inputValues(): InputValues {
    return this.inputType === "pellets" ? this.pelletsValues : this.oilValues;
  }
}
