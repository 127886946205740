
// Libraries
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { ButtonText } from "~/operations/messages";

// Components
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import { getLogoUrl } from "~/operations/shared";
@Component({
  components: {
    RouterLink,
  },
})
export default class Header extends Vue {
  @Prop() showNavigateBackwardsButton: boolean;
  @Prop() showGotoShopLink: boolean;

  ButtonText = ButtonText;

  resetCheckoutStatusAndNavigate() {
    this.$store.dispatch("checkout/resetCheckoutStatus");
    this.$router.go(-1);
  }

  goToComparison() {
    const storageKey = this.$route.path.includes("pelletspreise")
      ? "pellets-filter-params"
      : "oil-filter-params";

    const storedRoute = localStorage.getItem(storageKey);

    if (storedRoute) {
      const routeData = JSON.parse(storedRoute);

      if (routeData.type === "city") {
        // Redirect to the stored city path
        this.$router.push(routeData.path);
      } else if (routeData.type === "bestellung") {
        // Redirect to bestellung with stored queries
        const basePath = this.$route.path.includes("pelletspreise")
          ? "/pelletspreise/bestellung"
          : "/bestellung";

        this.$router.push({
          path: basePath,
          query: routeData.query,
        });
      }
    } else {
      const basePath = this.$route.path.includes("pelletspreise")
        ? "/pelletspreise"
        : "/";
      this.$router.push(basePath);
    }
  }

  get _getLogoUrl() {
    return getLogoUrl();
  }

  get colorClass() {
    // eslint-disable-next-line no-undef
    return process.env.MAIN_ESYOIL.includes(".com") ? "" : "io-blue";
  }

  get isCurrentPelletSite(): boolean {
    return this.$route.path.includes("pelletspreise");
  }
}
