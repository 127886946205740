export default {
  branchAddresses: [],
  profile: {
    profileId: "",
    _id: "",
    isActivated: false,
    name: "",
    isOffering: false,
    preferences: {
      slug: "",
      averageRating: 0,
      countRating: 0,
      ratingsLastYear: {
        countRating: 0,
        averageRating: 0,
        fiveStarRatings: 0,
        fourStarRatings: 0,
        threeStarRatings: 0,
        twoStarRatings: 0,
        oneStarRatings: 0,
      },
    },
    profileSlug: "",
    legalName: "",
    groupId: "",
    orderContactEmail: "",
    orderContactPhone: "",
    companyProfile: {
      description: "",
      imprint: "",
      vatId: "",
      address: "",
      contactPoint: "",
      awards: {
        ralCheck: false,
        tankCheck: false,
      },
      hasAGB: false,
      hasWithdrawal: false,
      arbiterShowText: false,
    },
    isTesting: false,
    shortName: "",
    address: {
      addressType: "",
      addressCountry: "",
      city: "",
      postalCode: "",
      street: "",
      streetNumber: "",
    },
  },
  uploads: {
    logoImageUrls: {
      small: "",
      medium: "",
      large: "",
    },
    profileImages: [],
    privacyPolicyFileUrl: "",
    withdrawalFileUrl: "",
    agbFileUrl: "",
    imprintFileUrl: "",
  },
  rating: {},
};
