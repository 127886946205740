
import { Component, Prop, Vue } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";

const NO_REVIEWS_MESSAGE = "noch keine Bewertungen";

@Component({
  components: {
    RouterLink,
  },
})
export default class RatingsDisplay extends Vue {
  @Prop({ default: "default" }) variant:
    | "default"
    | "static"
    | "priceComparisonRating";
  @Prop({ default: "default" }) ratingContext:
    | "default"
    | "static"
    | "comparsion-page-context"
    | "partner-page-context"
    | "dealer-profile";
  @Prop() ratingValue: string;
  @Prop() ratingCount: string;
  @Prop() ratingValueLastYear: string;
  @Prop() ratingCountLastYear: string;
  @Prop() ratingCountLabel: string;
  @Prop({ default: false }) hasDealerRating: boolean;
  @Prop({ default: false }) isRal: boolean;
  @Prop({ default: false }) priceComparisonRating: boolean;
  @Prop({ default: false }) showRatingsLabel: string | boolean;
  @Prop({ default: 0 }) countNeeded: number;

  // NOTE: esyoil is displaying the partner rating differently, in different contextes according to specific rules and regulations.
  returnDefaultRating() {
    if (Number(this.ratingCount) <= this.countNeeded) {
      return NO_REVIEWS_MESSAGE;
    }
    const FormattedRatingValue = Number(this.ratingValue);
    return FormattedRatingValue.toFixed(2);
  }
  returnPriceComparisonPageRating() {
    const FormattedRatingLastYearValue = Number(this.ratingValueLastYear);
    const FormattedRatingCount = Number(this.ratingCount);
    const FormattedRatingCountLastYear = Number(this.ratingCountLastYear);

    if (FormattedRatingCount <= 5) return NO_REVIEWS_MESSAGE;
    else {
      if (FormattedRatingCountLastYear === 0) return NO_REVIEWS_MESSAGE;
      else {
        return `${FormattedRatingLastYearValue.toFixed(2)}`;
      }
    }
  }

  returnPartnerRatinValueByRules() {
    switch (this.ratingContext) {
      case "default":
      case "static":
        return this.returnDefaultRating();
      case "partner-page-context":
      case "dealer-profile":
        return this.returnPartnerPageRating();
      case "comparsion-page-context":
      default:
        return this.returnPriceComparisonPageRating();
    }
  }

  returnStarIcon(rating: string | number, starIndex: number) {
    if (rating === null || rating === 0) return "star-regular.svg";
    if (rating >= starIndex) return "star-solid.svg";
    if (rating >= starIndex - 0.5) return "star-half-alt-solid.svg";
    return "star-regular.svg";
  }

  returnPartnerPageRating() {
    return this.returnPriceComparisonPageRating();
  }

  returnPartnerPageRatingCount() {
    return this.returnPriceComparisonPageRatingCount();
  }

  get hasRating() {
    return (
      this.returnPriceComparisonPageRatingCount() === NO_REVIEWS_MESSAGE &&
      this.priceComparisonRating
    );
  }

  get classNamesForRatingContainer() {
    return {
      "rating-container": true,
      "is--rating-container--true": this.hasRating,
      [`is--ratings-count-${this.returnPartnerRatinValueByRules()}`]: true,
      "price-comparison--true": this.priceComparisonRating === true,
    };
  }

  returnPriceComparisonPageRatingCount() {
    const FormattedRatingCount = Number(this.ratingCount);
    const FormattedRatingCountLastYear = Number(this.ratingCountLastYear);

    if (FormattedRatingCount <= 5) return NO_REVIEWS_MESSAGE;
    if (FormattedRatingCount > 5) {
      if (FormattedRatingCountLastYear === 0) return NO_REVIEWS_MESSAGE;
      if (FormattedRatingCountLastYear > 0) {
        return `(${FormattedRatingCount})`;
      }
    }
  }

  returnPartnerRatinCountByRules() {
    const ratingCount = Number(this.ratingCount);

    switch (this.ratingContext) {
      case "partner-page-context":
      case "dealer-profile":
        return this.returnPartnerPageRatingCount();
      case "comparsion-page-context":
        return this.returnPriceComparisonPageRatingCount();
      case "static":
        return "";
      case "default":
      default:
        return ratingCount >= this.countNeeded
          ? ratingCount.toLocaleString("de-DE")
          : "";
    }
  }
}
