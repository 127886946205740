
import { Component, namespace, Prop, Vue } from "nuxt-property-decorator";
import { ButtonText, PaymentTypes, Products } from "~/operations/messages";

import RouterLink from "~/components/atoms/text/RouterLink.vue";
import HeaderMenu from "~/components/molecules/nav/Menu/HeaderMenu.vue";
import BasicOverlay from "~/components/organisms/display/BasicOverlay.vue";
import ReducedPriceComparison from "~/components/organisms/form/ReducedPriceComparison.vue";
import BurgerMenuIcon from "~/components/organisms/icon/BurgerMenuIcon.vue";
import MyAccountIcon from "~/components/organisms/icon/MyAccountIcon.vue";
import eventBus from "~/operations/event.bus";
import { getLogoUrl, getMkLogin } from "~/operations/shared";

const UserModule = namespace("user");

type MenuItemType = {
  name: string;
  title: string;
  link: string;
  externalLink: boolean;
  additionalRouteMatches?: Array<string>;
};

@Component({
  components: {
    BurgerMenuIcon,
    MyAccountIcon,
    HeaderMenu,
    BasicOverlay,
    RouterLink,
    ReducedPriceComparison,
  },
})
export default class Header extends Vue {
  @Prop({ required: false }) logoSrc: string;
  @Prop({ required: false }) logoAlt: string;
  @Prop({ default: "default" }) variant: string;

  @UserModule.Getter("isLoggedIn")
  isLoggedIn: boolean;

  @UserModule.Getter("user")
  userData: any;

  ButtonText = ButtonText;
  Products = Products;
  PaymentTypes = PaymentTypes;

  isMenuVisible = false;

  menuItems = [
    {
      name: "Heizölpreise",
      title: "Zu den Heizölpreisen",
      link: "/heizoelpreise",
      externalLink: false,
      additionalRouteMatches: ["/"],
    },
    {
      name: "Pelletspreise",
      title: "Zu den Pelletspreisen",
      link: "/pelletspreise",
      externalLink: false,
    },
    {
      name: "Benzinpreise",
      title: "Zu den Benzinpreisen",
      link: "/benzinpreise",
      externalLink: true,
    },
  ];

  loginItems = [
    {
      name: "...",
      title: "Mein Konto",
      link: getMkLogin(),
      externalLink: true,
    },
  ];

  form: { visible: boolean } = {
    visible: true,
  };

  mounted() {
    // update user-login state
    this.loginItems[0].name = this.isLoggedIn
      ? this.userData.email
      : "Anmelden";

    eventBus.$on("header-menu-item-click", this.toggleMenu);
  }

  beforeDestroy() {
    eventBus.$off("header-menu-item-click");
    // Ensure we reset the overflow when component is destroyed
    document.body.style.overflow = "auto";
    const element =
      document.getElementById("default-page-layout") ||
      document.getElementById("shop-page-layout");
    if (element && window.innerWidth >= 1100) {
      element.style.paddingRight = "0";
    }
  }

  getColorClass(isLightMode = false) {
    const colorClass = process.env.MAIN_ESYOIL.includes(".com")
      ? ""
      : "io-blue";

    return isLightMode ? `${colorClass} light` : colorClass;
  }

  toggleMenu() {
    this.isMenuVisible = !this.isMenuVisible;
    if (this.isMenuVisible === true) {
      document.body.style.overflow = "hidden";
      const element =
        document.getElementById("default-page-layout") ||
        document.getElementById("shop-page-layout");
      if (element && window.innerWidth >= 1100) {
        element.style.paddingRight = "15px";
      }
    }
    if (this.isMenuVisible === false) {
      document.body.style.overflow = "auto";
      const element =
        document.getElementById("default-page-layout") ||
        document.getElementById("shop-page-layout");
      if (element && window.innerWidth >= 1100) {
        element.style.paddingRight = "0";
      }
    }
  }

  isMenuActive(menuItem: MenuItemType) {
    return (
      (this.$route.path.startsWith(menuItem.link) &&
        this.$route.matched.length > 0) ||
      (menuItem.additionalRouteMatches &&
        menuItem.additionalRouteMatches.includes(this.$route.path))
    );
  }

  get currentPageType(): "oil" | "pellets" {
    return this.$route.path.includes("pelletspreise") ? "pellets" : "oil";
  }

  get priceComparisonTitle(): string {
    return this.currentPageType === "pellets"
      ? "Pellets-Preisvergleich:"
      : "Heizöl-Preisvergleich:";
  }

  get amountLabel(): string {
    return this.currentPageType === "pellets" ? "Kilogramm" : "Liter";
  }

  get amountPlaceholder(): string {
    return this.currentPageType === "pellets" ? "3000" : "Ihr Bedarf";
  }

  get buttonText(): string {
    return this.currentPageType === "pellets"
      ? "Pelletspreise Vergleichen"
      : ButtonText.compareOilPrices;
  }

  get defaultAmount(): number {
    return 3000;
  }

  get minAmount(): number {
    return this.currentPageType === "pellets" ? 1000 : 500;
  }

  get maxAmount(): number {
    return this.currentPageType === "pellets" ? 50000 : 32000;
  }

  get homeUrl(): string {
    if (this.currentPageType === "pellets") {
      const hasEmptyQuery = Object.keys(this.$route.query).length === 0;
      return hasEmptyQuery ? "/" : "/pelletspreise";
    }
    return "/";
  }

  get isOrderPage() {
    return (
      this.$route.path === "/bestellung" ||
      this.$route.path === "/pelletspreise/bestellung" ||
      this.$route.params.id?.startsWith("heizoelpreise-") ||
      this.$route.params.id?.startsWith("pelletpreise-")
    );
  }

  get logoUrl() {
    return getLogoUrl();
  }
}
